import { Global } from '@emotion/react'
import { ThemeProvider } from '@emotion/react'
import * as Sentry from '@sentry/nextjs'
import {
  StytchB2BProvider,
  useStytchB2BClient,
  useStytchMemberSession,
} from '@stytch/nextjs/b2b'
import { createStytchB2BHeadlessClient } from '@stytch/nextjs/b2b/headless'
import 'highlight.js/styles/github.css'
import App, { AppContext } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import 'rsuite/dist/rsuite.min.css'

import EnvironmentContext from '@context/environment'
import ThemeContext from '@context/theme'
import UserContext from '@context/user'
import { EnvironmentSelected } from '@lib/analytics'
import api from '@lib/api'
import {
  getEnvironmentIdCookie,
  hasSessionCookie,
  removeAllCookies,
  removeEnvironmentIdCookie,
  setEnvironmentIdCookie,
} from '@lib/cookies'
import { darkTheme, getSavedTheme, lightTheme, setSavedTheme } from '@lib/theme'
import '@static/fonts/encodeSans/encodeSans.css'
import '@static/fonts/inter/inter.css'

const stytch = createStytchB2BHeadlessClient(
  process.env.STYTCH_PUBLIC_TOKEN || '',
)

const PortalAppContext = ({ children }) => {
  // Derive hooks
  const router = useRouter()
  const stytch = useStytchB2BClient()
  const { isInitialized } = useStytchMemberSession()

  // Derive state.
  const [userData, setUserData] = useState<UserData>(null)
  const [selectedEnvironment, setSelectedEnvironment] =
    useState<Environment>(null)
  const [sessionExists, setSessionExists] = useState<boolean>(false)

  const getUserData = async () => {
    try {
      const user = await api.users.getMe()

      if (!user) {
        try {
          await api.auth.logout()
          await stytch.session.revoke({ forceClear: true })
        } catch (error) {
          console.error('Error logging out but removing cookie anyway', error)
        } finally {
          removeAllCookies()
        }

        await router.push('/login')
      }

      setUserData(user)

      // If the user has no environments, don't set an environment.
      if (!user.custodian.environments.length) {
        removeEnvironmentIdCookie()
        return
      }

      // If the user has no selected environment, set the "Development" environment.
      const environmentId = getEnvironmentIdCookie()
      if (!environmentId) {
        const environment = user.custodian.environments.find(
          (env) => env.name === 'Development',
        )
        if (environment) {
          setSelectedEnvironment(environment)
        }
        return
      }

      // If the user has a selected environment, set it.
      const environment = user.custodian.environments.find(
        (env) => env.id === environmentId,
      )
      if (environment) setSelectedEnvironment(environment)
      else {
        const environment = user.custodian.environments.find(
          (env) => env.name === 'Development',
        )
        if (environment) {
          setSelectedEnvironment(environment)
        }
      }
    } catch (error) {
      console.error(`Error getting user data: `, error)
      Sentry.captureException(error)

      // log user out forcefully because the session is likely stale
      if (hasSessionCookie()) {
        try {
          await api.auth.logout()
          await stytch.session.revoke({ forceClear: true })
        } catch (error) {
          console.error('Error logging out but removing cookie anyway', error)
        } finally {
          removeAllCookies()
        }
        await router.push('/login')
      }
    }
  }

  useEffect(() => {
    if (sessionExists && isInitialized) {
      getUserData()
    }
  }, [sessionExists, isInitialized])

  // poll for the session cookie
  useEffect(() => {
    const intervalId = setInterval(() => {
      const sessionExists = hasSessionCookie()
      setSessionExists(sessionExists)

      if (sessionExists) clearInterval(intervalId)
    }, 200)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <UserContext.Provider value={{ user: userData, setUser: setUserData }}>
      <EnvironmentContext.Provider
        value={{
          environment: selectedEnvironment,
          setEnvironment: (environment) => {
            // Set the environment state.
            setSelectedEnvironment(environment)

            // Set the environment cookie.
            setEnvironmentIdCookie(environment.id)

            // Track the event.
            analytics.track(EnvironmentSelected, {
              environmentId: environment.id,
              environmentName: environment.name,
            })
          },
        }}
      >
        {children}
      </EnvironmentContext.Provider>
    </UserContext.Provider>
  )
}

const PortalApp = ({ Component, pageProps, isBotTraffic }: PortalAppProps) => {
  const [theme, setTheme] = useState('light')

  const themeMode = theme === 'light' ? lightTheme : darkTheme

  const setMode = (mode: string) => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode)
    setSavedTheme(mode)
  }

  const toggleTheme = () => {
    theme === 'light' ? setMode('dark') : setMode('light')
  }

  useEffect(() => {
    setTheme(getSavedTheme())
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={themeMode}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          />
        </Head>
        <Script
          id="isBotTraffic"
          dangerouslySetInnerHTML={{
            __html: `window.isBotTraffic = ${isBotTraffic};`,
          }}
        />
        <StytchB2BProvider stytch={stytch}>
          <Global
            styles={{
              body: {
                backgroundColor: themeMode.colors.white,
                color: themeMode.colors.text.body,
              },
              tspan: {
                color: themeMode.colors.text.axisLabel,
              },
            }}
          />
          <PortalAppContext>
            <Component {...pageProps} />
          </PortalAppContext>
        </StytchB2BProvider>
        {!isBotTraffic && (
          <script
            dangerouslySetInnerHTML={{
              __html: `if (!window.isBotTraffic) {analytics.load('${process.env.SEGMENT_WRITEKEY}');}`,
            }}
          />
        )}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

PortalApp.getInitialProps = async (ctx: AppContext) => {
  const initialProps = await App.getInitialProps(ctx)
  const { req } = ctx.ctx

  return {
    ...initialProps,
    isBotTraffic:
      req?.headers?.['user-agent']?.includes('DatadogSynthetics') || false,
  }
}

export default PortalApp
