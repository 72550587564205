import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

import environments from './environments'
import members from './members'
import sso from './sso'

export const update = async (data: Record<string, any>): Promise<Custodian> => {
  const { data: custodian } = await apiWrapper(
    axios.put<Custodian>(
      buildConnectApiUrl(`/app/custodian`),
      data,
      requestConfig,
    ),
  )

  return custodian
}

export default {
  environments,
  members,
  sso,
  update,
}
